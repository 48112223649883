import { EventBus } from 'EventBus'
import RootTableActionMixin from 'GroomyRoot/mixins/TableAction.js'

export default {
    mixins: [RootTableActionMixin],
	methods: {
		//CONTRAT DE MONTE // SAILLIE
		goToAddContractConfig(horse_id) {
			EventBus.$emit("TableAction::goToAddContractConfig", {})
		},
		goToEditContractConfig(contract_config) {
			const contractconfig_id = this.constructRouteParams(contract_config, 'contractconfig_id')
			let params = { contractconfig_id: contractconfig_id }
			
			EventBus.$emit("TableAction::goToEditContractConfig", params)
		},
		goToDeleteContractConfig(contract_config) {
			const contractconfig_id = this.constructRouteParams(contract_config, 'contractconfig_id')
			let params = { contractconfig_id: contractconfig_id }
			
			EventBus.$emit("TableAction::goToDeleteContractConfig", params)
		},
		
		goToAddConditionsToContractConfigGlobal() {
			EventBus.$emit("TableAction::goToAddConditionsToContractConfigGlobal", {})
		},
		goToEditConditionsContractConfigGlobal(contract_config_global_type_monte) {
			const contract_config_global_type_monte_ = this.constructRouteParams(contract_config_global_type_monte, 'contractconfigglobaltypemonte_id')
			let params = { contractconfigglobaltypemonte_id: contract_config_global_type_monte_ }

			EventBus.$emit("TableAction::goToEditConditionsContractConfigGlobal", params)
		},
		goToDistributeConditionsContractConfigGlobal(contract_config_global_type_monte) {
			const contract_config_global_type_monte_ = this.constructRouteParams(contract_config_global_type_monte, 'contractconfigglobaltypemonte_id')
			let params = { contractconfigglobaltypemonte_id: contract_config_global_type_monte_ }

			EventBus.$emit("TableAction::goToDistributeConditionsContractConfigGlobal", params)
		},
		goToAddStallionToConditionsParent() {
        	EventBus.$emit("TableAction::goToAddStallionToConditionsParent")
		},
		goToAddStallionToContractConfigGlobal() {
        	EventBus.$emit("TableAction::goToAddStallionToContractConfigGlobal")
		},
		goToDeleteStallionToContractConfigGlobal(contract_configs) {
			const contractconfig_ids = contract_configs.map((contract_config) => {
				return contract_config.contractconfig_id
			})
        	EventBus.$emit("TableAction::goToDeleteStallionToContractConfigGlobal", contractconfig_ids)
		},
		goToExecDistributeConditions(contract_config_type_monte) {
			const monte_ids = this.constructRouteParams(contract_config_type_monte, 'contractconfigtypemonte_id')
        	EventBus.$emit("TableAction::goToExecDistributeConditions", monte_ids)
		},
		goToDeleteSupplierInvoice(invoice_suppliers) {
			const invoicesuppliers_id = this.constructRouteParams(invoice_suppliers, 'invoice_id')
        	EventBus.$emit("TableAction::goToDeleteSupplierInvoice", invoicesuppliers_id)
		},
		goToAddContractConfigTypeMonte(horse_id) {
			EventBus.$emit("TableAction::goToAddContractConfigTypeMonte", {})
		},
		goToEditContractConfigTypeMonte(contract_config_type_monte) {
			const contract_config_type_monte_ = this.constructRouteParams(contract_config_type_monte, 'contractconfigtypemonte_id')
			let params = { contractconfigtypemonte_id: contract_config_type_monte_ }

			EventBus.$emit("TableAction::goToEditContractConfigTypeMonte", params)
		},
		
		goToDeleteContractConfigTypeMonte(contract_config_type_monte) {
			const contract_config_type_monte_ = this.constructRouteParams(contract_config_type_monte, 'contractconfigtypemonte_id')
			let params = { contractconfigtypemonte_id: contract_config_type_monte_ }

			EventBus.$emit("TableAction::goToDeleteContractConfigTypeMonte", params)
        },
		
		goToArchiveContractConfigTypeMonte(contract_config_type_monte) {
			EventBus.$emit("TableAction::goToArchiveContractConfigTypeMonte", contract_config_type_monte[0])
        },
		goToDeleteModeleContractConfig(modele) {
			const modele_ = this.constructRouteParams(modele, 'model_id')
			let params = { model_id: modele_ }

			EventBus.$emit("TableAction::goToDeleteModeleContractConfig", params)
        },
        goToValideFacturationContractAValider(avenant_articles) {
        	EventBus.$emit("TableAction::goToValideFacturationContractAValider", avenant_articles)
		},

		goToDuplicateSeason(season) {
			const season_id = this.constructRouteParams(season, 'season_id')
			let params = { season_id: season_id }

			EventBus.$emit("TableAction::goToDuplicateSeason", params)
		},
		
		goToAddContractConfigModel() {
        	EventBus.$emit("TableAction::goToAddContractConfigModel")
		},
		goToDeleteSeasonModel(models) {
        	EventBus.$emit("TableAction::goToDeleteSeasonModel", models[0])
		},
		goToEditContractConfigModel(configmodel) {
        	EventBus.$emit("TableAction::goToEditContractConfigModel", configmodel)
		},
		goToPrintBlankContract(configmodel) {
			EventBus.$emit("TableAction::goToPrintBlankContract", configmodel)
		},
		goToDuplicateCourrier(model) {
        	EventBus.$emit("TableAction::goToDuplicateCourrier", model)
		},
		goToDistributeSeasonModel(configmodel) {
        	EventBus.$emit("TableAction::goToDistributeSeasonModel", configmodel)
		},
		goToAddStallionToModeleParent() {
        	EventBus.$emit("TableAction::goToAddStallionToModeleParent")
		},
		goToExecDistributeSeasonModel(models) {
			const model_ids = this.constructRouteParams(models, 'model_id')
        	EventBus.$emit("TableAction::goToExecDistributeSeasonModel", model_ids)
		},
		goToAddMonteResa() {
        	EventBus.$emit("TableAction::goToAddMonteResa")
		},
		goToEditMonteResa(resas) {
        	EventBus.$emit("TableAction::goToEditMonteResa", resas)
		},
		goToDeleteResa(resas) {
			EventBus.$emit("TableAction::goToDeleteResa", resas)
		},
		goToAddResa() {
        	EventBus.$emit("TableAction::goToAddResa")
		},
		goToBonSortieResa(lines) {
        	EventBus.$emit("TableAction::goToBonSortieResa", lines)
		},
		goToNotifyExpediteur(lines) {
        	EventBus.$emit("TableAction::goToNotifyExpediteur", lines)
		},
		goToAckExpedition(lines) {
        	EventBus.$emit("TableAction::goToAckExpedition", lines)
		},
		goToAckDelivered(lines) {
        	EventBus.$emit("TableAction::goToAckDelivered", lines)
		},
		goToAckNotDelivered(lines) {
        	EventBus.$emit("TableAction::goToAckNotDelivered", lines)
		},
        goToEditResa(lines) {
        	EventBus.$emit("TableAction::goToEditResa", lines[0])
		},
        goToEditResaBis(lines) {
        	EventBus.$emit("TableAction::goToEditResaBis", lines[0])
		},
		goToDeleteMonteResa(lines) {
        	EventBus.$emit("TableAction::goToDeleteMonteResa", lines)
		},
		goToEditCentre(lines) {
        	EventBus.$emit("TableAction::goToEditCentre", lines)
		},
		goToFastEditContract(line) {
			EventBus.$emit("TableAction::goToFastEditContract", line)
		},
		goToMarkContractAsSended(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToMarkContractAsSended", contract_ids)
		},
		goToExportContract() {
			EventBus.$emit("TableAction::goToExportContract")
		},
		goToActDgLotContract(lines) {
			EventBus.$emit('TableAction::goToActDgLotContract', lines)
		},

        // SYNDICATION
        goToAddSyndicParts() {
			EventBus.$emit("TableAction::goToAddSyndicParts")
        },

        goToEditPart(saillie) {
			EventBus.$emit("TableAction::goToEditPart", saillie)
        },

        goToEditMultiPart(saillies) {
			EventBus.$emit("TableAction::goToEditMultiPart", saillies)
        },

        gotToAssignParts(saillie) {
			EventBus.$emit("TableAction::gotToAssignParts", saillie)
        },

        goToInvoicePart(saillies) {
			EventBus.$emit("TableAction::goToInvoicePart", saillies)
        },

        goToEditDateCession(part) {
			EventBus.$emit("TableAction::goToEditDateCession", part[0])
        },

		goToSendContract(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToSendContract", contract_ids)
		},
		goToCourrierContract(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToCourrierContract", contract_ids)
		},
		goToAddCourrier() {
			EventBus.$emit("TableAction::goToAddCourrier")
		},
		goToEditCourrier(model) {
			EventBus.$emit("TableAction::goToEditCourrier", {model})
		},
		goToDeleteCourrier(models) {
			const models_ids = models.map((model) => {
				return model.model_id
			})
			EventBus.$emit("TableAction::goToDeleteCourrier", models_ids)
		},
		goToArchiveModel(models) {
			EventBus.$emit('TableAction::goToArchiveModel', models.map(t => t.model_id))
		},
		goToPdfCourrier(model) {
			EventBus.$emit("TableAction::goToPdfCourrier", {model})
		},
		goToAddCourrierFiche() {
			EventBus.$emit("TableAction::goToAddCourrierFiche")
		},
		goToEditCourrierFiche(model) {
			EventBus.$emit("TableAction::goToEditCourrierFiche", {model})
		},
		goToSendCourrierFiche(model) {
			EventBus.$emit("TableAction::goToSendCourrierFiche", {model})
		},
		goToDeleteCourrierFiche(models) {
			EventBus.$emit("TableAction::goToDeleteCourrierFiche", models)
		},
		goToSendCourrierTiers(models) {
			const models_ids = models.map((model) => {
				return model.model_id
			})
			EventBus.$emit("TableAction::goToSendCourrierTiers", models_ids)
		},
		goToPrintRelanceContract(contracts) {
			const contract_ids = contracts.map((contract) => {
				return contract.contract_id
			})
			EventBus.$emit("TableAction::goToPrintRelanceContract", contract_ids)
		},

		// ACTES
		goToAddActeEcho(horses = []) {
			let params = { from: this.$route.name }
            params = this.constructHorseRouteParams(horses, params)
            
            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjoutEcho', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},
		goToAddActeDG(horses = []) {
			let params = { from: this.$route.name }
            params = this.constructHorseRouteParams(horses, params)
            
            if(this.button_action_event_only) {
                this.sendEvent('TableAction::acteAjoutDG', params)
            }
            else {
                this.$router.push({ name: 'acteAjout', params: params })
            }
		},

		// FACTURES
		goToAbandonFacturationAValider(horse_actes) {
			EventBus.$emit("TableAction::goToAbandonFacturationAValider", horse_actes)
		},
		goToAccounted(invoices) {
			const invoices_ids = invoices.map((invoice) => {
				return invoice.invoice_id
			})
			EventBus.$emit('TableAction::goToAccounted', invoices_ids)
		},
		goToModifyInvoiceSaillie(invoices) {
			const invoices_ids = invoices.map((invoice) => {
				return invoice.invoice_id
			})
			EventBus.$emit('TableAction::goToModifyInvoiceSaillie', invoices_ids)
		},
		goToNoPrint(invoices) {
			const invoices_ids = invoices.map((invoice) => {
				return invoice.invoice_id
			})
			EventBus.$emit('TableAction::goToNoPrint', invoices_ids)
		},
		goToDeleteInvoice(invoices) {
			const invoice_ids = this.constructRouteParams(invoices, 'invoice_id')
			const invoice_nums = this.constructRouteParams(invoices, 'invoice_num')

			let params = { 
				invoice_ids: invoice_ids, 
				invoice_nums: invoice_nums, 
			}

			EventBus.$emit("TableAction::goToDeleteInvoice", params)
		},
		goToDownloadPdfInvoice(invoices) {
			const invoices_ids = invoices.map((invoice) => {
				return invoice.invoice_id
			})

			EventBus.$emit("TableAction::goToDownloadPdfInvoice", invoices_ids)
		},
		goToReorganizeInvoiceDetails(invoice_details) {
			EventBus.$emit('TableAction::goToReorganizeInvoiceDetails', invoice_details.map(t => t.invoicedetails_id))
		},
		goToChangeTiersBaf(invoice_details) {
			EventBus.$emit('TableAction::goToChangeTiersBaf', invoice_details.map(t => t.invoicedetails_id))
		},
		goToEditHT(invoice_details) {
			EventBus.$emit('TableAction::goToEditHT', invoice_details)
		},
		goToAddBAF(tiers_horse) {
			EventBus.$emit('TableAction::goToAddBAF', tiers_horse)
		},
		goToPrintInfos(impayes) {
			const tiers_ids = impayes.map((impaye) => {
				if(impaye.invoice && impaye.invoice.tiers) {
					return impaye.invoice.tiers.tiers_id
				}
				return impaye.tiers.tiers_id	
			})
			const nb_selected = impayes.length
			const params = {tiers_ids, nb_selected}
			EventBus.$emit('TableAction::goToPrintInfos', params)
		},

		goToSetRelanceAllManuelle(tiers) {
			EventBus.$emit("TableAction::goToSetRelanceAllManuelle", tiers)
		},

		goToSendAllInvoice(tiers) {
			EventBus.$emit("TableAction::goToSendAllInvoice", tiers)
		},

		// INTRA LOCATIONS
		goToAddIntraLocation() {
			EventBus.$emit("TableAction::goToAddIntraLocation")
		},
		goToEditIntraLocation(location) {
			EventBus.$emit("TableAction::goToEditIntraLocation", location)
		},
		goToDeleteIntraLocation(location) {
			EventBus.$emit("TableAction::goToDeleteIntraLocation", location[0])
		},
		goToAddArticlesToAvenant() {
			EventBus.$emit("TableAction::goToAddArticlesToAvenant")
		},
		goToImportInvoiceDetail() {
			EventBus.$emit("TableAction::goToImportInvoiceDetail")
		},
		goToRecalculArticlesAvenant() {
			EventBus.$emit("TableAction::goToRecalculArticlesAvenant")
		},

		// JUMENTERIE
		goToPrintInfosHorseJumenterie(lines) {
			EventBus.$emit('TableAction::goToPrintInfosHorseJumenterie', lines)
		},
		goToAddCross() {
			EventBus.$emit('TableAction::goToAddCross')
		},
		goToChangeDpsNum(lines) {
			EventBus.$emit('TableAction::goToChangeDpsNum', lines)
		},
		goToActDgLot(lines) {
			EventBus.$emit('TableAction::goToActDgLot', lines)
		},
		goToSendDg(lines) {
			EventBus.$emit('TableAction::goToSendDg', lines)
		},
		goToAddActeDonneuse(lines) {
			EventBus.$emit("TableAction::goToAddActeDonneuse", lines)
		},
		goToAddActePorteuse(lines) {
			EventBus.$emit("TableAction::goToAddActePorteuse", lines)
		},

		// NOTES
		goToAddNote() {
			EventBus.$emit("TableAction::goToAddNote")
		},
		goToAddActNote(acts) {
			const act = acts[0]
			this.$router.push({ name: 'notesManagement', params: { horse_id: act.actes_horse, acte_id: act.actes_id }})
		},
		goToAddHorseNote(horses) {
			const horse = horses[0]
			this.$router.push({ name: 'notesManagement', params: { horse_id: horse.horse_id }})
		},
		goToAddHorseFicheNote() {
			EventBus.$emit("TableAction::goToAddHorseFicheNote")
		},
		goToAddTiersFicheNote() {
			EventBus.$emit("TableAction::goToAddTiersFicheNote")
		},
		goToEditNote(notes) {
			const note = notes[0]
			if(note.horse) {
				this.$router.push({ name: 'notesManagement', params: { note_id: note.note_id, horse_id: note.horse.horse_id }})
			}
			else if(note.tiers) {
				this.$router.push({ name: 'notesManagement', params: { note_id: note.note_id, tiers_id: note.tiers.tiers_id }})
			}
		},
		goToDeleteNote(notes) {
			EventBus.$emit("TableAction::goToDeleteNote", notes.map(n => n.note_id))
		},
		goToNotifyNote(notes) {
			EventBus.$emit("TableAction::notifyNote", notes.map(n => n.note_id))
		},
		goToShareNote(notes) {
			EventBus.$emit("TableAction::goToShareNote", notes.map(n => n.note_id))
		},
		goToSendNote(notes) {
			EventBus.$emit("TableAction::goToSendNote", notes.map(n => n.note_id))
		},


		// HORSE SELECTION
		goToSelectHorses(horses) {
			EventBus.$emit("TableAction::goToSelectHorses", horses.map(h => h.horse_id))
		},

		// HORSE IMPORT
		goToImportHorses() {
			EventBus.$emit("TableAction::goToImportHorses", {})
		},

		// TIERS SELECTION
		goToSelectTiers(tiers) {
			EventBus.$emit("TableAction::goToSelectTiers", tiers.map(t => t.tiers_id))
		},

		// ACCOUNTING PLAN SELECTION
		goToSelectAccountingPlan(accounting_plans) {
			EventBus.$emit("TableAction::goToSelectAccountingPlan", accounting_plans.map(ap => ap.accountingplan_id))
		},

		// RESULTAT SANITAIRE
		goToEditResult(results) {
			EventBus.$emit("TableAction::goToEditResult", results)
		},
		goToAddResultNote(results) {
			EventBus.$emit("TableAction::goToAddResultNote", results)
		},

		goToAnalyse(actes) {
			EventBus.$emit("TableAction::goToAnalyse", actes)
		},

		goToAddEditDemandeAnalyse(analyse) {
			EventBus.$emit("TableAction::goToAddEditDemandeAnalyse", analyse[0])
		},

		goToAddOrdonnanceFromAct(actes){
			EventBus.$emit("TableAction::goToAddOrdonnanceFromAct", actes)
		},

		// TIERS
		goToPrintInfosTiers(tiers) {
			EventBus.$emit('TableAction::goToPrintInfosTiers', tiers.map(t => t.tiers_id))
		},
		goToFusionTiers(tiers) {
			EventBus.$emit('TableAction::goToFusionTiers', tiers.map(t => t.tiers_id))
		},
		goToArchiveTiers(tiers) {
			EventBus.$emit('TableAction::goToArchiveTiers', tiers.map(t => t.tiers_id))
		},
		goToDeleteTiersGroup(tiers) {
			EventBus.$emit('TableAction::goToDeleteTiersGroup', tiers.map(t => t.tiers_id))
		},
		goToAddTiersGroup() {
			EventBus.$emit('TableAction::goToAddTiersGroup', {})
		},
		goToUnarchiveTiers(tiers) {
			EventBus.$emit('TableAction::goToUnarchiveTiers', tiers.map(t => t.tiers_id))
		},
		goToExportTiers() {
			EventBus.$emit('TableAction::goToExportTiers')
		},
		goToTransferHorsesOtherTiers(tiershorse) {
			EventBus.$emit('TableAction::goToTransferHorsesOtherTiers', tiershorse.map(t => t.tiershorse_id))
		},
		goToManageQualifsTiers(tiers) {
			EventBus.$emit("TableAction::goToManageQualifsTiers", tiers.map(t => t.tiers_id))
		},
		goToSendMailTiers(tiers) {
			EventBus.$emit("TableAction::goToSendMailTiers", tiers.map(t => t.tiers_id))
		},
		goToPrintExtraitCompte(tiers) {
			EventBus.$emit("TableAction::goToPrintExtraitCompte", tiers.map(t => t.tiers_id))
		},

		// TRIGGER DELETE ACTE 
		goToDeleteActeTrigger(actes) {
			EventBus.$emit("TableAction::DeleteActes::Trigger", actes)
		},

		goToValidHorse(tiers_horses) {
			EventBus.$emit("TableAction::validHorses", tiers_horses)
		},

		// Monte resa
		goToEditMonteOrder(monte_resa) {
			EventBus.$emit("TableAction::goToEditMonteOrder", monte_resa[0])
		},

		// EMBRYONS
		goToEditEmbryon(embryon) {
			EventBus.$emit("TableAction::goToEditEmbryon", embryon[0])
		},

		goToDeleteEmbryon(embryon) {
			EventBus.$emit("TableAction::goToDeleteEmbryon", embryon[0])
		},

		goToExpditionEmbryon(embryons) {
			EventBus.$emit("TableAction::goToExpditionEmbryon", embryons)
		},

		goToAddEmbryon(embryon) {
			EventBus.$emit("TableAction::goToAddEmbryon")
		},

		// ACTE TYPE
		goToEditOperateurActeType(actetype) {
			EventBus.$emit("TableAction::EditOperateurActeType", actetype[0])
		},
		goToEditMaxdelay(actestypes) {
			EventBus.$emit("TableAction::EditMaxdelay", actestypes)
		},
		
		// CONTACT
		goToArchiveContact(contacts) {
			EventBus.$emit("TableAction::goToArchiveContact", contacts)
		},
		
		goToUnarchiveContact(contacts) {
			EventBus.$emit("TableAction::goToUnarchiveContact", contacts)
		},

		// STOCK SEMENCE
		goToAddLocSemence() {
			EventBus.$emit("TableAction::goToAddLocSemence")
		},

		goToEditLocSemence(stock) {
			EventBus.$emit("TableAction::goToEditLocSemence", stock)
		},

		goToDeleteLocSemence(stocks) {
			EventBus.$emit("TableAction::goToDeleteLocSemence", stocks)
		},

		goToReorganizeChildren(stock) {
			EventBus.$emit("TableAction::goToReorganizeChildren", stock)
		},

		goToReorganizeParent(stock) {
			EventBus.$emit("TableAction::goToReorganizeParent", stock)
		},

		goToInventaireStock() {
			EventBus.$emit("TableAction::goToInventaireStock")
		},

		goToAddEntreePaillettes() {
			EventBus.$emit("TableAction::goToAddEntreePaillettes")
		},

		goToImportStock() {
			EventBus.$emit("TableAction::goToImportStock")
		},
		
		goToExportGlobalCuve() {
			EventBus.$emit("TableAction::goToExportGlobalCuve")
		},

		goToAddStockStallion() {
			EventBus.$emit("TableAction::goToAddStockStallion")
		},

		goToDestroyStock(stocks) {
			EventBus.$emit("TableAction::goToDestroyStock", stocks)
		},

		goToEditStock(stocks) {
			EventBus.$emit("TableAction::goToEditStock", stocks[0])
		},

		goToRetourStocks() {
			EventBus.$emit("TableAction::goToRetourStocks")
		},

		goToSortieStock() {
			EventBus.$emit("TableAction::goToSortieStock")
		},

		goToHistoryStock(stocks) {
			EventBus.$emit("TableAction::goToHistoryStock", stocks[0])
		},

		goToDeleteStockTiers(stocks) {
			EventBus.$emit("TableAction::goToDeleteStockTiers", stocks[0])
		},

		// PENSION LISTE
		goToPrintPensions() {
			EventBus.$emit("TableAction::goToPrintPensions")
		},
		// HORSE LISTE
		goToManageCategories(horses) {
			this.$router.push({ name: 'HorseCategoriesManagement', params: { horse_ids: horses.map(h => h.horse_id)}})
		},
		goToAddPension(horses) {
			EventBus.$emit("TableAction::goToAddPension", horses.map(h => h.horse_id))
		},
		goToAddPensionFromTiers(horses) {
			EventBus.$emit("TableAction::goToAddPensionFromTiers", horses.map(h => h.horse.horse_id))
		},
		goToManageQualifsHorse(horses) {
			EventBus.$emit("TableAction::goToManageQualifsHorse", horses.map(h => h.horse_id))
		},
		goToArchiveHorse(horses) {
			EventBus.$emit("TableAction::goToArchiveHorse", horses.map(h => h.horse_id))
		},
		// HORSE ARCHIVED LIST
		goToUnArchiveHorse(horses) {
			EventBus.$emit("TableAction::goToUnArchiveHorse", horses.map(h => h.horse_id))
		},

		goToSelectLieuStationnement(tiers) {
			EventBus.$emit("TableAction::selectLieuStationnement", tiers[0])
		},
		goToMarkAsRegulated(payments) {
			EventBus.$emit("TableAction::goToMarkAsRegulated", payments.map(p => p.payment_id))
		},
		goToAccountedPayment(payments) {
			const payment_ids = payments.map((payment) => {
				return payment.payment_id
			})
			EventBus.$emit('TableAction::goToAccountedPayment', payment_ids)
		},

		goToArchiveLieu(lieux) {
			EventBus.$emit('TableAction::ArchiveLieu', lieux)
		},
		goToSetDefaultLieu(lieu) {
			EventBus.$emit('TableAction::SetDefaultLieu', lieu[0])
		},

		// PRODUCTION
		goToImportDescendants() {
			EventBus.$emit('TableAction::goToImportDescendants')
		},
		// RS CONFIG
		goToChangeFormatRs(format) {
			EventBus.$emit('TableAction::goToChangeFormatRs', format[0])
		},

		goToEditQualificationTiers(contracts) {
			EventBus.$emit('TableAction::goToEditQualificationTiers', contracts)
		},

		goToArchiveCouple(sms) {
			EventBus.$emit('TableAction::goToArchiveCouple', sms)
		},

		goToDupliqueActe(actes) {
			EventBus.$emit('TableAction::goToDupliqueActe', actes[0].actes_id)
		},

		goToPrintActs(actes) {
			const acte_ids = actes.map((acte) => {
				return acte.actes_id
			})
			EventBus.$emit('TableAction::goToPrintActs', acte_ids)
		},

		goToReindexContract(contracts) {
			EventBus.$emit('TableAction::goToReindexContract', contracts)
		},

		// QUOTATION
		goToAddQuotation() {
			EventBus.$emit('TableAction::goToAddQuotation')
		},
		goToEditQuotation(quotation) {
			this.$router.push({ name: 'EditDevis', params: {quotation_id: quotation[0].quotation_id}})
		},
		goToConvertToBAF(quotation) {
			EventBus.$emit('TableAction::goToConvertToBAF', quotation[0])
		},
		goToPdfQuotation(quotation) {
			EventBus.$emit('TableAction::goToPdfQuotation', quotation[0])
		},
		goToSendQuotation(quotation) {
			EventBus.$emit('TableAction::goToSendQuotation', quotation[0])
		},
		goToAddQuotationDocument() {
			EventBus.$emit('TableAction::goToAddQuotationDocument')
		},
		goToEditQuotationDocument(quotation_document) {
			EventBus.$emit('TableAction::goToEditQuotationDocument', quotation_document[0])
		},
		goToDeleteQuotationDocument(quotation_document) {
			EventBus.$emit('TableAction::goToDeleteQuotationDocument', quotation_document[0])
		},
		goToDownloadQuotationDocument(quotation_document) {
			EventBus.$emit('TableAction::goToDownloadQuotationDocument', quotation_document[0])
		},
		goToDuplicateQuotation(quotation) {
			EventBus.$emit('TableAction::goToDuplicateQuotation', quotation[0])
		},

		// DOCUMENT CONTRAT
		goToAddContractDocument() {
			EventBus.$emit('TableAction::goToAddContractDocument')
		},
		goToEditContractDocument(contract_document) {
			EventBus.$emit('TableAction::goToEditContractDocument', contract_document[0])
		},
		goToDeleteContractDocument(contract_document) {
			EventBus.$emit('TableAction::goToDeleteContractDocument', contract_document[0])
		},
		goToDownloadContractDocument(contract_document) {
			EventBus.$emit('TableAction::goToDownloadContractDocument', contract_document[0])
		},

		// VEHICULE
		goToAddVehicule() {
			EventBus.$emit('TableAction::goToAddVehicule')
		},
		goToEditVehicule(vehicule) {
			EventBus.$emit('TableAction::goToEditVehicule', vehicule[0])
		},
		goToArchiveVehicule(vehicule) {
			EventBus.$emit('TableAction::goToArchiveVehicule', vehicule[0])
		},

		// DEPLACEMENT
		goToAddDeplacement() {
			EventBus.$emit('TableAction::goToAddDeplacement')
		},
		goToEditDeplacement(deplacement) {
			EventBus.$emit('TableAction::goToEditDeplacement', deplacement[0])
		},
		goToPdfDeplacement(deplacement) {
			EventBus.$emit('TableAction::goToPdfDeplacement', deplacement[0])
		},
		goToAddDeplacementFromSuggestion(suggestions) {
			EventBus.$emit('TableAction::goToAddDeplacementFromSuggestion', suggestions)
		},
		goToDeleteDeplacement(deplacements) {
			EventBus.$emit('TableAction::goToDeleteDeplacement', deplacements)
		},

		// COURSE
		goToValideArticleCourse(course) {
			EventBus.$emit('TableAction::goToValideArticleCourse', course)
		},

		goToEditArticleTva(articles) {
			EventBus.$emit('TableAction::goToEditArticleTva', articles)
		},
		goToEditArticleCompteComptable(articles) {
			EventBus.$emit('TableAction::goToEditArticleCompteComptable', articles)
		},
		goToSendContractDocument(doc) {
			EventBus.$emit('TableAction::goToSendContractDocument', doc[0])
		},
		goToSendContractDocuments(doc) {
			EventBus.$emit('TableAction::goToSendContractDocument', doc)
		},
		goToSendQuotationDocument(doc) {
			EventBus.$emit('TableAction::goToSendQuotationDocument', doc[0])
		},

		goToSoldeAvoir(invoices) {
			EventBus.$emit('TableAction::goToSoldeAvoir', invoices[0])
		},

		goToSoldeInvoice(invoices) {
			EventBus.$emit('TableAction::goToSoldeInvoice', invoices)
		},
		// ARTICLE
		goToImportArticles() {
			EventBus.$emit("TableAction::goToImportArticles", {})
		},
		// EMAILS TRACKING
		goToArchiveTracking(trackings) {
			const trackings_ids = trackings.map((tracking) => {
				return tracking.mandrilltracking_id
			})
			EventBus.$emit('TableAction::goToArchiveTracking', trackings_ids)
		},
		goToPreviewInvoiceWithModel(invoice_details) {
			const invoicedetails_id = this.constructRouteParams(invoice_details, 'invoicedetails_id')

            let params = { invoicedetails_id: invoicedetails_id }

			EventBus.$emit("TableAction::goToPreviewInvoiceWithModel", params)
		},
		// HISTORIQUE DE FLUX 
		goToCourrierStock(flux) {
			EventBus.$emit('TableAction::goToCourrierStock', flux)
		},
		goToEditStockFlux(flux) {
			EventBus.$emit('TableAction::goToEditStockFlux', flux)
		},

		goToImportDNAS(contracts) {
			EventBus.$emit('TableAction::goToImportDNAS', contracts)
		},

		goToAddMouvementFromSuggestion(params) {
			EventBus.$emit('TableAction::goToAddMouvementFromSuggestion', params)
		},

		goToAskDPS(params) {
			EventBus.$emit('TableAction::goToAskDPS', params)
		},

		goToSendMailCAInvitation(params) {
			EventBus.$emit('TableAction::goToSendMailCAInvitation', params)
		},

		goToAddContractLot() {
			EventBus.$emit('TableAction::goToAddContractLot')
		},

		goToEtatFacturation() {
			EventBus.$emit('TableAction::goToEtatFacturation')
		},

		goToDeleteSoldeContract(articles) {
			EventBus.$emit('TableAction::DeleteSoldeContract::Trigger', articles)
		},

		goToDeleteConditionsContractConfigGlobal(conditions) {
			EventBus.$emit('TableAction::goToDeleteConditionsContractConfigGlobal', conditions[0])
		},

		goToAddTaskPlanning() {
			EventBus.$emit('TableAction::goToAddTaskPlanning')
		},

		goToEditTaskPlanning(planning) {
			EventBus.$emit('TableAction::goToEditTaskPlanning', planning[0])
		},

		goToArchiveTaskPlanning(planning) {
			EventBus.$emit('TableAction::goToArchiveTaskPlanning', planning)
		},

		goToAddInvoiceSupplier() {
			EventBus.$emit('TableAction::goToAddInvoiceSupplier')
		},

		goToControlInvoiceSupplier(invoices) {
			EventBus.$emit('TableAction::goToControlInvoiceSupplier', invoices[0])
		},

		goToDeleteInvoiceSupplier(invoices) {
			EventBus.$emit('TableAction::goToDeleteInvoiceSupplier', invoices[0])
		},

		goToEditInvoiceSupplier(invoices) {
			EventBus.$emit('TableAction::goToEditInvoiceSupplier', invoices[0])
		},

		goToAddPaymentSupplier(invoices) {
			EventBus.$emit('TableAction::goToAddPaymentSupplier', invoices)
		},

		goToCancelPaymentSupplier(is_payments) {
			EventBus.$emit("TableAction::goToCancelPaymentSupplier", is_payments)
		},

		goToAddChargeAccountSupplier(invoices) {
			EventBus.$emit('TableAction::goToAddChargeAccountSupplier', invoices[0])
		},
		goToExportComptableSupplier(invoices) {
			const invoicesupplier_ids = invoices.map(invoice => invoice.suppliersocr_id)

			EventBus.$emit("TableAction::goToExportComptableSupplier", invoicesupplier_ids)
		},
		goToAccountedSupplier(invoices) {
			const invoicesupplier_ids = invoices.map(invoice => invoice.suppliersocr_id)

			EventBus.$emit("TableAction::goToAccountedSupplier", invoicesupplier_ids)
		},
		goToAssignTransaction(transactions) {
			EventBus.$emit('TableAction::goToAssignTransaction', transactions[0])
		},
	}
}
